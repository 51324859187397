@keyframes fade {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }

}
@keyframes moveInOpacity {
    from {
      opacity: 0;
      visibility: hidden;
    } to {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes moveOutOpacity {
    from {
      opacity: 1;
      visibility: visible;
    } to {
      opacity: 0;
      visibility: hidden;
    }
  }