.overlay {
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.overlay-white {
  background-color: rgba(255, 255, 255, 0.85);
}

  *{
    box-sizing:  border-box;
  }
  